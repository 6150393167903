define("ember-cli-sanitize-html/templates/components/sanitize-html", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "c8cKXu4f",
    "block": "[[[41,[31,1],[[[1,\"  \"],[18,1,[[31,3]]],[1,\"\\n\"]],[]],[[[41,[31,4],[[[1,\"    \"],[2,[36,3]],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[34,3]],[1,\"\\n\"]],[]]]],[]]]],[\"&default\"],false,[\"if\",\"hasBlock\",\"yield\",\"sanitizedValue\",\"raw\"]]",
    "moduleName": "ember-cli-sanitize-html/templates/components/sanitize-html.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});